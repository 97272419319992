import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const BuildingProductsPage = () => {
  return (
    <Layout hero={<HeroImage title="Building Products" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center mt-4">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  Orrcon Steel branches carry a wide range of building supplies. Orrcon Steel supply all the little extra bits to get your job done with ease!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/building-products">
                    <h2 className="title">BUILDING PRODUCTS</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Cutting Discs
                      </Link>
                      <p>Quality disc metal cut offs in a range of sizes.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Masks & Gloves
                      </Link>
                      <p>Orrcon Steel have a range of welding masks. We also provide.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Deformed Bar
                      </Link>
                      <p>Deformed bar is used where extra reinforcing is required to fabric.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Rods & Wires
                      </Link>
                      <p>Welding rods and wire are used in the welding process as a filler.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Trade Tools
                      </Link>
                      <p>Our trade tools range will assist you with your building or roofing.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Mesh
                      </Link>
                      <p>Reinforcing mesh to AS/NZS 4671 standard.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default BuildingProductsPage

export const Head = () => <title>Building Products</title>
